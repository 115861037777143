import React from "react";
import "./App.css";
import RouterWrap from "./RouterWrap";

import MainTheme from "./theme/MainTheme";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { QueryClient, QueryClientProvider } from "react-query";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      refetchOnMount: false,
      refetchIntervalInBackground: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    },
  },
});

const App = () => {
  return (
    <div className="App">
      <ToastContainer />
      <QueryClientProvider client={queryClient}>
        <MainTheme>
          <RouterWrap />
        </MainTheme>
      </QueryClientProvider>
    </div>
  );
};

export default App;
