export const brandOptions = [
  {
    name: "apple",
    value: "apple",
  },
  {
    name: "samsung",
    value: "samsung",
  },
  {
    name: "huawei",
    value: "huawei",
  },
  {
    name: "xiaomi",
    value: "xiaomi",
  },
  {
    name: "oppo",
    value: "oppo",
  },
  {
    name: "vivo",
    value: "vivo",
  },
  {
    name: "oneplus",
    value: "oneplus",
  },
  {
    name: "google (pixel)",
    value: "google",
  },
  {
    name: "lg",
    value: "lg",
  },
  {
    name: "sony",
    value: "sony",
  },
  {
    name: "motorola",
    value: "motorola",
  },
  {
    name: "htc",
    value: "htc",
  },
  {
    name: "nokia",
    value: "nokia",
  },
  {
    name: "asus",
    value: "asus",
  },
  {
    name: "lenovo",
    value: "lenovo",
  },
  {
    name: "zte",
    value: "zte",
  },
  {
    name: "meizu",
    value: "meizu",
  },
  {
    name: "realme",
    value: "realme",
  },
  {
    name: "honor",
    value: "honor",
  },
  {
    name: "acer",
    value: "acer",
  },
  {
    name: "blackberry",
    value: "blackberry",
  },
  {
    name: "alcatel",
    value: "alcatel",
  },
  {
    name: "infinix",
    value: "infinix",
  },
  {
    name: "tecno",
    value: "tecno",
  },
  {
    name: "gionee",
    value: "gionee",
  },
  {
    name: "leeco (letv)",
    value: "leeco",
  },
  {
    name: "panasonic",
    value: "panasonic",
  },
  {
    name: "micromax",
    value: "micromax",
  },
  {
    name: "coolpad",
    value: "coolpad",
  },
  {
    name: "karbonn",
    value: "karbonn",
  },
  {
    name: "lava",
    value: "lava",
  },
  {
    name: "sharp",
    value: "sharp",
  },
  {
    name: "kyocera",
    value: "kyocera",
  },
  {
    name: "yotaphone",
    value: "yotaphone",
  },
  {
    name: "cat (caterpillar)",
    value: "cat",
  },
  {
    name: "elephone",
    value: "elephone",
  },
  {
    name: "ulefone",
    value: "ulefone",
  },
  {
    name: "wiko",
    value: "wiko",
  },
  {
    name: "doogee",
    value: "doogee",
  },
  {
    name: "nubia",
    value: "nubia",
  },
  {
    name: "vernee",
    value: "vernee",
  },
  {
    name: "wileyfox",
    value: "wileyfox",
  },
  {
    name: "archos",
    value: "archos",
  },
  {
    name: "bq",
    value: "bq",
  },
  {
    name: "energizer",
    value: "energizer",
  },
  {
    name: "alcatel",
    value: "alcatel",
  },
  {
    name: "umidigi",
    value: "umidigi",
  },
  {
    name: "fairphone",
    value: "fairphone",
  },
  {
    name: "bluboo",
    value: "bluboo",
  },
  {
    name: "oukitel",
    value: "oukitel",
  },
];

export const operatingSystemOptions = [
  {
    name: "android",
    value: "android",
  },
  {
    name: "ios",
    value: "ios",
  },
];
