import React, { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";

import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { Typography, Container } from "@material-ui/core";

import InputField from "components/controls/InputField";
import PrimaryButton from "components/controls/PrimaryButton";
import { toast } from "react-toastify";

import { useUpdateUser } from "store/api/hooks/useUpdateUser";
import { useUserData } from "store/api/hooks/useUserData";
import Loader from "components/common/Loader";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const UserProfilePage = () => {
  const { mutate: onUpdate, isLoading: updateInProgress } = useUpdateUser();
  const { data: userData, isLoading: userLoading } = useUserData();

  const user = userData?.data;

  const isLoading = userLoading || updateInProgress;

  const {
    handleSubmit,
    control,
    reset,
    formState: { isDirty },
  } = useForm();

  const handleUserUpdate = useCallback(
    async (data) => {
      const { firstName, lastName, phoneNumber, username } = data;
      const payload = {
        id: user?._id,
        firstName,
        lastName,
        phoneNumber,
        username,
      };

      onUpdate(payload, {
        onSuccess: (res) => {
          toast.success(res.data.data);
        },
        onError: (error) => {
          const errMsg = error?.response?.data?.error;
          toast.error(errMsg);
        },
      });
    },
    [onUpdate, user?._id]
  );

  const classes = useStyles();
  useEffect(() => {
    if (userData) {
      reset({
        firstName: user?.firstName,
        lastName: user?.lastName,
        phoneNumber: user?.phoneNumber,
        username: user?.username,
        email: user?.email,
      });
    }
  }, [userData]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          Podaci o korisniku
        </Typography>
        <form
          className={classes.form}
          onSubmit={handleSubmit(handleUserUpdate)}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <InputField
                control={control}
                name="firstName"
                variant="outlined"
                fullWidth
                label="Ime"
                autoFocus
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputField
                control={control}
                variant="outlined"
                fullWidth
                label="Prezime"
                name="lastName"
                autoComplete="lname"
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <InputField
                control={control}
                variant="outlined"
                fullWidth
                label="Ime profila"
                name="username"
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <InputField
                control={control}
                variant="outlined"
                fullWidth
                disabled
                label="Email adresa"
                name="email"
                autoComplete="email"
              />
            </Grid>
            <Grid item xs={12}>
              <InputField
                control={control}
                variant="outlined"
                fullWidth
                label="Kontakt telefon"
                name="phoneNumber"
                autoComplete="pNumber"
              />
            </Grid>
          </Grid>
          <PrimaryButton
            disabled={!isDirty}
            isLoading={isLoading}
            type="submit"
            fullWidth
            style={{
              marginTop: "20px",
            }}
            variant="contained"
            color="primary"
          >
            Azuriraj podatke
          </PrimaryButton>
        </form>
      </div>
    </Container>
  );
};

export default UserProfilePage;
