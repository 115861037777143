import https from "https";
import axiosApiInstance from "./axios";

// import { API_URL } from '../../../lib/constants/env.constants';
import { setHeader } from "./setHeader";

// const API_URL = "http://localhost:5500/";
// const API_URL = "https://api.polovnitelefoni.info/";

// const API_URL = "https://mobile-ecommerce-backend.herokuapp.com/";

// const API_URL = "https://mobile-info-api-183016665ed6.herokuapp.com/";

const API_URL = process.env.REACT_APP_API_URL;

const httpsAgent = new https.Agent({
  rejectUnauthorized: false,
});

export const makeRequest = (
  url,
  { body, method, headers, cancelToken = "" }
) => {
  if (typeof window === "undefined") {
    setHeader("Origin", API_URL);
    setHeader("Referer", API_URL);
  }

  return axiosApiInstance({
    method,
    url: `${API_URL}${url}`,
    data: body,
    withCredentials: false,
    headers: {
      "Content-type": "application/json; charset=UTF-8",
      "x-api-key": "1vaHd3v",
      ...headers,
    },
    httpsAgent,
    xsrfHeaderName: "Authorization",
    cancelToken,
  });
};
