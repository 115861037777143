import React from "react";

import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { useController, useFormContext } from "react-hook-form";

const Select = ({ control, name, options, defaultValue, ...props }) => {
  const methods = useFormContext();
  const errors = methods?.errors;

  const {
    field: { ref, onChange, ...inputProps },
    meta: { invalid, isTouched, isDirty },
  } = useController({
    name,
    control,
    defaultValue,
  });
  return (
    <Autocomplete
      {...inputProps}
      options={options}
      getOptionLabel={(option) => option.name}
      getOptionSelected={(option) => option.name}
      onChange={(e, data) => onChange(data)}
      renderInput={(params) => (
        <TextField
          {...params}
          {...props}
          variant="outlined"
          error={invalid}
          helperText={errors[name]?.message}
        />
      )}
    />
  );
};

export default Select;
