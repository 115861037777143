import Loader from "components/common/Loader";
import MobileList from "components/Mobile/MobileList";
import React from "react";
import { useState } from "react";
import { useMobileList } from "store/api/hooks/useMobileList";

const MobilePage = () => {
  const [paginationSettings, setPagginationSetting] = useState({
    offset: 0,
    limit: 1000,
  });
  const { data: response, isLoading } = useMobileList(paginationSettings);
  const mobileList = response?.data;

  if (isLoading) {
    return <Loader />;
  }

  return <MobileList mobileList={mobileList?.data} />;
};

export default MobilePage;
