import React from "react";
import { withRouter } from "react-router-dom";

import Loader from "components/common/Loader";

import MobileItemForm from "components/Mobile/MobileItemForm/MobileItemForm";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { useMobileItem } from "store/api/hooks/useMobileItem";
import { useMobileImages } from "store/api/hooks/useGetMobileImages";

import ScrapedPhoneDetails from "components/Mobile/MobileItemForm/ScrapedPhoneDetails";

const defaultValues = {
  _id: "",
  name: "",
  price: null,
  operatingSystem: "",
  screenSize: "",
  camera: "",
  ramMemory: "",
  memory: "",
};

const MobileItem = () => {
  const params = useParams();
  const mobileId = params?.id;

  const isNewAction = mobileId === "new";

  const { data: response, isLoadingData } = useMobileItem({
    id: isNewAction ? null : mobileId,
  });

  const { isFetching: loadingImages } = useMobileImages({
    id: isNewAction ? null : mobileId,
  });

  const mobileDetails = response?.data?.data;

  if (isLoadingData || loadingImages) {
    return <Loader />;
  }

  if (!isNewAction && !mobileDetails) {
    return <Loader />;
  }

  return (
    <>
      {/* <ScrapedPhoneDetails /> */}
      <MobileItemForm
        createNew={isNewAction}
        mobileDetails={isNewAction ? defaultValues : mobileDetails}
      />
    </>
  );
};

export default withRouter(MobileItem);
