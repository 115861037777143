import { useQuery } from "react-query";
import { makeRequest } from "../request/makeRequest";
import { QUERY_KEYS } from "store/constants";

const getUser = () => makeRequest(`users/me/`, { method: "GET" });

export function useUserData() {
  return useQuery([QUERY_KEYS.user], getUser, {
    enabled: true,
    refetchOnMount: true,
  });
}
