import React from "react";
import { toast } from "react-toastify";

import { GoogleLogin } from "@react-oauth/google";
import { JWTdecode } from "lib/getUser";
import { Box } from "@material-ui/core";
import { useSocialLogin } from "store/api/hooks/useSocialLogin";

function GoogleAuthBtn() {
  const { mutate: onSocialLogin } = useSocialLogin();

  const onSuccess = async (credentialResponse) => {
    if ("credential" in credentialResponse) {
      const user = JWTdecode(credentialResponse?.credential);
      onSocialLogin(
        {
          firstName: user?.given_name,
          lastName: user?.family_name,
          email: user?.email,
          phoneNumber: "0000000",
        },
        {
          onSuccess: async (res) => {
            const token = res.data?.token;
            sessionStorage.setItem("MEC-TOKEN", token);
            setTimeout(() => {
              // window.location.href = "#/mobiles";
              window.location.reload();
            }, 1000);
          },
          onError: (err) => {
            toast.error(err.response.data.error);
          },
        }
      );
    } else {
      console.log("offline");
    }
  };

  const onFailure = (error) => {
    toast.error({
      description: "Google auth error",
    });
  };

  return (
    <Box display={"flex"} justifyContent={"center"}>
      <GoogleLogin
        size="large"
        onFailure={onFailure}
        onSuccess={onSuccess}
        onError={() => {
          console.log("Login Failed");
        }}
      />
    </Box>
  );
}

export default GoogleAuthBtn;
