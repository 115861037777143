import React, { useEffect, useMemo, useState } from "react";

import { createSrcFromBuffer } from "lib/createScrFromBuffer";

import {
  Box,
  CardMedia,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  makeStyles,
  Paper,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";

import UploadImage from "components/Mobile/MobileItemForm/UploadImage";
import mobilePlaceholder from "images/mobile-placeholder.png";
import { useMobileImages } from "store/api/hooks/useGetMobileImages";
import { useDeleteMobileItemImage } from "store/api/hooks/useDeleteMobileItemImage";
import Loader from "components/common/Loader";
import { useSetMainImage } from "store/api/hooks/useSetMainImage";

const useStyles = makeStyles((theme) => ({
  card: {
    position: "relative",
  },
  deleteBtn: {
    margin: theme.spacing(1),
    position: "absolute",
    right: -30,
    top: -30,
  },
}));

const ImageSection = ({ setImageFiles, imageFiles, mobileId }) => {
  const classes = useStyles();

  const { data: response, isLoadingImages } = useMobileImages({
    id: mobileId,
  });

  const { mutate: onDeleteImageItem, isLoading: deleteInProgress } =
    useDeleteMobileItemImage();
  const { isLoading: setMainImgInProgress, mutateAsync: onSetMainImage } =
    useSetMainImage();

  const mobileImages = response?.data?.data;

  if (isLoadingImages || deleteInProgress || setMainImgInProgress) {
    return <Loader height="15vh" />;
  }

  return (
    <Box mt={3} width="100%">
      <Box mb={2}>
        <Grid container spacing={4}>
          {mobileImages?.length
            ? mobileImages.map((imgObj) => {
                const oneItem = mobileImages?.length === 1;
                return (
                  <Grid
                    item
                    xs={12}
                    sm={3}
                    md={2}
                    key={imgObj._id}
                    className={classes.card}
                  >
                    <Paper>
                      <IconButton
                        disabled={oneItem || imgObj.isMain}
                        aria-label="delete"
                        className={classes.deleteBtn}
                        onClick={() => {
                          if (!oneItem && !imgObj?.isMain) {
                            onDeleteImageItem({
                              id: imgObj._id,
                              key: imgObj?.s3key,
                            });
                          }
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>

                      <FormControlLabel
                        label="Profilna slika"
                        control={
                          <Checkbox
                            checked={
                              mobileImages?.find(
                                (image) => image?._id === imgObj._id
                              )?.isMain
                            }
                            onChange={(_, checked) => {
                              console.log(imgObj);
                              if (checked) {
                                onSetMainImage({
                                  imageId: imgObj._id,
                                  mobileId: imgObj?.owner,
                                });
                              }
                            }}
                            name="checkedA"
                          />
                        }
                      />

                      <Box p={1} display="flex" justifyContent="center">
                        <CardMedia
                          src={imgObj?.src || mobilePlaceholder}
                          component="img"
                          title="mobile image"
                        />
                      </Box>
                    </Paper>
                  </Grid>
                );
              })
            : null}
        </Grid>
      </Box>
      <Box>
        <UploadImage
          setImageFiles={setImageFiles}
          imageFiles={imageFiles}
          mobileImages={mobileImages}
        />
      </Box>
    </Box>
  );
};

export default ImageSection;
