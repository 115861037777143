import { dark, indigo } from "./colors";
import { red } from "@material-ui/core/colors";


export const themes = {
  default: {
    palette: {
      type: "light",
      primary: {
        light: indigo[200],
        main: indigo[500],
        dark: indigo[800],
      },
      secondary: {
        light: dark[100],
        main: dark[300],
        dark: dark[600],
      },
      white: "#fff",
      background: {
        paper: "#FFFFFF",
        default: "#f6f7f9",
        defaultOpacity: "rgb(246, 247, 249, 0.7)"
      },
      error: {
        main: red.A400,
      },
    },
    status: {
      danger: "orange",
    },

  },
  defaultDark: {
    palette: {
      type: "dark",
      primary: {
        light: indigo[200],
        main: indigo[500],
        dark: indigo[800],
      },
      secondary: {
        light: dark[100],
        main: dark[300],
        dark: dark[600],
      },
      background: {
        paper: "#1E2125",
        default: "#121212",
        defaultOpacity: "rgb(18, 18, 18, 0.7)"

      },
      white: "#fff",
      error: {
        main: red.A400,
      },
    },
    status: {
      danger: "orange",
    },

  },
};
