import { useQueryClient, useMutation } from "react-query";
import { makeRequest } from "../request/makeRequest";
import { MOBILE_URL, QUERY_KEYS } from "store/constants";

const updateMobile = (payload) =>
  makeRequest(`${MOBILE_URL}/${payload.id}`, {
    method: "PATCH",
    body: payload,
  });

export function useUpdateMobile() {
  const queryClient = useQueryClient();

  return useMutation(updateMobile, {
    onSuccess: () => {
      queryClient.refetchQueries(QUERY_KEYS.mobileList);
      queryClient.refetchQueries(QUERY_KEYS.mobileItem);
      // queryClient.refetchQueries(QUERY_KEYS.mobileItemImages);
    },
    onError: (error) => {},
  });
}
