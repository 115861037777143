import { useQueryClient, useMutation } from "react-query";
import { makeRequest } from "../request/makeRequest";
import { MOBILE_URL } from "store/constants";

const createSingleImage = ({ file, id, isMain = false }) => {
  if (!id) return;
  try {
    const formData = new FormData();
    formData.append("image", file, file.name);
    formData.append("isMain", isMain);

    makeRequest(`${MOBILE_URL}/${id}/image`, {
      method: "POST",
      body: formData,
    });

    // makeRequest(`${MOBILE_URL}/${id}/image`, {
    //   method: "post",
    //   body: file,
    //   headers: {
    //     "Content-Type": "multipart/form-data",
    //   },
    // });
  } catch (error) {
    console.log(error, error.message, "erorr in createSingleImage");
  }
};

export function useCreateSingleImage() {
  const queryClient = useQueryClient();

  return useMutation(createSingleImage, {
    onSuccess: () => {
      // queryClient.invalidateQueries(QUERY_KEYS.mobileItemImages);
      setTimeout(() => {
        // queryClient.refetchQueries(QUERY_KEYS.mobileItemImages);
        // queryClient.refetchQueries(QUERY_KEYS.mobileList);
      }, 1500);
    },
    onError: (error) => {},
  });
}
