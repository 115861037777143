import { useQuery } from "react-query";
import { makeRequest } from "../request/makeRequest";
import { MOBILE_URL, QUERY_KEYS } from "store/constants";

const fetchMobileImages = (payload) =>
  makeRequest(`${MOBILE_URL}/${payload.id}/images`, {
    method: "GET",
  });

export function useMobileImages(payload) {
  return useQuery(
    [QUERY_KEYS.mobileItemImages, payload.id],
    () => fetchMobileImages(payload),
    {
      enabled: !!payload.id,
    }
  );
}
