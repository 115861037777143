import { useQueryClient, useMutation } from "react-query";
import { makeRequest } from "../request/makeRequest";
import { MOBILE_URL, QUERY_KEYS } from "store/constants";
import { delay } from "lib/delay";

const createMultipleImages = ({ imageFiles, id }) => {
  try {
    imageFiles?.forEach(async (files) => {
      await delay(1000);

      await files?.forEach((file) => {
        const formData = new FormData();
        formData.append("image", file, file.name);
        makeRequest(`${MOBILE_URL}/${id}/image`, {
          method: "POST",
          body: formData,
        });
      });
    });
  } catch (error) {
    console.log("erorr in createMultipleImages");
  }
};

export function useCreateMultipleImages() {
  const queryClient = useQueryClient();

  return useMutation(createMultipleImages, {
    onSuccess: () => {
      // queryClient.invalidateQueries(QUERY_KEYS.appSettings);
      setTimeout(() => {
        queryClient.refetchQueries(QUERY_KEYS.mobileItemImages);
      }, 1500);
    },
    onError: (error) => {},
  });
}
