import React from "react";
import PropTypes from "prop-types";
import {
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
  useFilters,
  useGlobalFilter,
} from "react-table";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import MaUTable from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";

import LaunchIcon from "@material-ui/icons/Launch";
import FileCopyIcon from "@material-ui/icons/FileCopy";

import {
  Box,
  Grid,
  IconButton,
  makeStyles,
  TableHead,
  Tooltip,
  Typography,
  useTheme,
} from "@material-ui/core";

import TablePaginationActions from "./TablePaginationActions";

const useStyles = makeStyles((theme) => ({
  paginationRoot: {
    display: "flex",
  },
  headerStyle: {
    backgroundColor: theme.palette.primary.main,
    fontWeight: "bold",
    "& th, span, svg": {
      color: "#fff",
    },
  },
}));

const ReactTable = (props) => {
  const {
    showAction,
    handleActionsClick,
    columns,
    DefaultCell,
    CustomTableHead,
    data,
    TableFilter,
    disabled,
  } = props;

  const defaultColumn = {
    Cell: DefaultCell,
  };
  const instance = useTable(
    {
      columns,
      data,
      defaultColumn,
      autoResetSelectedRows: false,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      showAction &&
        hooks.allColumns.push((columns) => [
          ...columns,

          {
            id: "options",
            Header: "",
            Cell: ({ row }) => {
              if (row.original.expired) {
                return (
                  <Box display={"flex"} justifyContent={"end"}>
                    <Tooltip title="Otvori">
                      <IconButton
                        aria-label="more"
                        onClick={(ev) => {
                          ev.preventDefault();
                          const bonusData = row.original;
                          handleActionsClick().openAction(bonusData);
                        }}
                      >
                        <LaunchIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Obnovi oglas">
                      <IconButton
                        aria-label="more"
                        onClick={(ev) => {
                          ev.preventDefault();
                          const bonusData = row.original;
                          handleActionsClick().renewAction(bonusData);
                        }}
                      >
                        <AutorenewIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>
                );
              }
              return (
                <Box display={"flex"}>
                  <Tooltip title="Otvori">
                    <IconButton
                      aria-label="more"
                      onClick={(ev) => {
                        ev.preventDefault();
                        const bonusData = row.original;
                        handleActionsClick().openAction(bonusData);
                      }}
                    >
                      <LaunchIcon />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Kopiraj">
                    <IconButton
                      aria-label="copy"
                      onClick={(ev) => {
                        ev.preventDefault();
                        const bonusData = row.original;
                        handleActionsClick().copyAction(bonusData);
                      }}
                    >
                      <FileCopyIcon />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Obriši">
                    <IconButton
                      aria-label="delete"
                      onClick={(ev) => {
                        ev.preventDefault();
                        const bonusData = row.original;
                        handleActionsClick().deleteAction(bonusData);
                      }}
                    >
                      <DeleteOutlineIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              );
            },
          },
        ]);
    }
  );
  const {
    getTableProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    gotoPage,
    setPageSize,
    state: { pageIndex, pageSize, globalFilter },
  } = instance;

  const theme = useTheme();
  const classes = useStyles();

  const handleChangePage = (event, newPage) => {
    gotoPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(Number(event.target.value));
  };

  return (
    <>
      {TableFilter && (
        <TableFilter instance={instance} globalFilter={globalFilter} />
      )}
      <TableContainer>
        <MaUTable>
          <TableHead>
            <TableRow>
              <TablePagination
                className={classes.paginationRoot}
                rowsPerPageOptions={[5, 10, 25]}
                colSpan={3}
                count={rows.length}
                rowsPerPage={pageSize}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                page={pageIndex}
                ActionsComponent={TablePaginationActions}
                onChangePage={handleChangePage}
              />
            </TableRow>
          </TableHead>
        </MaUTable>
        <MaUTable {...getTableProps()} size="small">
          <CustomTableHead
            headerGroups={headerGroups}
            headerStyle={classes.headerStyle}
            disabled={disabled}
          />
          <TableBody>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <TableRow
                  style={{
                    backgroundColor: row.original.expired
                      ? theme.palette.warning["main"]
                      : i % 2 === 0
                      ? theme.palette.grey["200"]
                      : theme.palette.grey["50"],
                  }}
                  {...row.getRowProps()}
                >
                  {row.cells.map((cell) => {
                    return (
                      <TableCell {...cell.getCellProps()}>
                        {cell.render("Cell")}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
            {page.length === 0 && (
              <TableRow>
                <TableCell>
                  <Typography>No data</Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </MaUTable>
      </TableContainer>
    </>
  );
};

ReactTable.defaultProps = {
  showAction: false,
  handleActionClick: () => {},
  disabled: false,
};

ReactTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  showAction: PropTypes.bool.isRequired,
  CustomTableHead: PropTypes.func.isRequired,
  DefaultCell: PropTypes.func.isRequired,
};

export default ReactTable;
