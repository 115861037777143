export const QUERY_KEYS = {
  reqister: "register",
  user: "user",
  mobileList: "mobile-list",
  mobileItem: "mobile-item",
  mobileItemImages: "mobile-item-images",
};

export const AUTH = "auth";

export const MOBILE_URL = "mobiles";
export const IMAGE_URL = "images";
