import { Box, Link } from "@material-ui/core";
import useIsMobile from "lib/hooks/useIsMobile";
import React from "react";

function WebPages() {
  const isMobile = useIsMobile();
  return (
    <Box
      display={"flex"}
      marginY={4}
      fontSize={20}
      flexDirection={isMobile ? "column" : "row"}
    >
      <Box>Detaljne podatke o vašem telefonu možete pronaći na: </Box>
      <Box marginX={"5px"}>
        <Link href="https://mobilnisvet.com/" target="_blank">
          Mobilni svet
        </Link>
      </Box>{" "}
      ili
      <Box marginX={"5px"}>
        <Link href="https://www.gsmarena.com/" target="_blank">
          GSM Arena
        </Link>{" "}
        WEB stranicama.
      </Box>
    </Box>
  );
}

export default WebPages;
