import { useQueryClient, useMutation } from "react-query";
import { makeRequest } from "../request/makeRequest";

const AUTH = "auth";

const register = (body) =>
  makeRequest(`${AUTH}/register`, { method: "POST", body });

export function useRegister() {
  // const queryClient = useQueryClient();

  return useMutation(register, {
    onSuccess: () => {
      // queryClient.invalidateQueries(QUERY_KEYS.appSettings);
    },
    onError: (error) => {},
  });
}
