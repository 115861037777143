import React from "react";
import { useForm } from "react-hook-form";

import Avatar from "@material-ui/core/Avatar";
import CssBaseline from "@material-ui/core/CssBaseline";

import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import InputField from "components/controls/InputField";
import PrimaryButton from "components/controls/PrimaryButton";
import { Button, Dialog, DialogActions, DialogTitle } from "@material-ui/core";
import { toast } from "react-toastify";
import { useChangePassword } from "store/api/hooks/useChangePassword";
import { getUser } from "lib/getUser";

const ChangePassword = ({ toggleDialogOpen, open }) => {
  const { register, handleSubmit, errors, control } = useForm();
  //   {
  // }
  const { mutate: onChangePassword, isLoading } = useChangePassword();
  const classes = useStyles();
  const user = getUser();

  const onSubmit = (values) => {
    onChangePassword(values, {
      onSuccess: async (res) => {
        toast.success("Lozinka je uspešno promenjena!");
      },
      onError: (err) => {
        console.log(err.response, "err herer");
        toast.error(err.response.data.error);
      },
    });
  };
  return (
    <Dialog
      open={open}
      onClose={toggleDialogOpen}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
        Promeni lozinku
      </DialogTitle>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>

          <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <InputField
                  defaultValue={user.email}
                  disabled
                  fullWidth
                  control={control}
                  register={register}
                  name="email"
                  placeholder=""
                  label="E-mail adresa"
                  error={errors?.email?.message}
                />
              </Grid>
              <Grid item xs={12}>
                <InputField
                  fullWidth
                  control={control}
                  register={register}
                  name="password"
                  placeholder=""
                  label="Trenutna lozinka"
                  type="password"
                  error={errors?.password?.message}
                />
              </Grid>
              <Grid item xs={12}>
                <InputField
                  fullWidth
                  control={control}
                  register={register}
                  name="newPassword"
                  placeholder=""
                  label="Nova lozinka"
                  type="password"
                  error={errors?.newPassword?.message}
                />
              </Grid>
              <Grid item xs={12}></Grid>
            </Grid>
            <DialogActions>
              <Button autoFocus onClick={toggleDialogOpen} color="primary">
                Izađi
              </Button>
              <PrimaryButton
                type="submit"
                isLoading={isLoading}
                onClick={toggleDialogOpen}
                color="primary"
              >
                Potvrdi
              </PrimaryButton>
            </DialogActions>
          </form>
        </div>
      </Container>
    </Dialog>
  );
};

export default ChangePassword;

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(2),
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
}));
