import React, { useState } from "react";
import clsx from "clsx";

import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { IconButton, makeStyles, Toolbar, Typography } from "@material-ui/core";

import MenuIcon from "@material-ui/icons/Menu";
import SettingsIcon from "@material-ui/icons/Settings";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import EditIcon from "@material-ui/icons/Edit";

import { getUser } from "lib/getUser";
import ChangePassword from "components/ChangePassword/ChangePassword";
import { useCallback } from "react";

const AppTololbar = ({ pageName, open, setOpen, disableOpenSidebar }) => {
  const [changePassword, setChangePassword] = useState(false);

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  // const { data, isLoading } = useUserData();
  // const user = data?.data;

  const user = getUser();

  const handleDrawerOpen = () => {
    !disableOpenSidebar && setOpen(true);
  };

  const handleSettingsClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseSettings = () => {
    setAnchorEl(null);
  };

  const toggleChangePasswordDialog = useCallback(() => {
    setChangePassword((prev) => !prev);
  }, []);

  const handleLogout = async () => {
    // const loginResponse = await dispatch(logout(history));
    sessionStorage.removeItem("MEC-TOKEN");
    // await dispatch(logout(true));
    // history.push("auth/login");
    window.location.reload();
    // if (loginResponse.meta.requestStatus === "fulfilled") {
    //   history.push("/login");
    // }
  };

  return (
    <>
      <Toolbar className={classes.toolbar}>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
        >
          <MenuIcon />
        </IconButton>
        <Typography
          component="h1"
          variant="h6"
          color="inherit"
          noWrap
          className={classes.title}
        >
          {/* {pageName} {user?.email} */}
          {user?.email}
        </Typography>
        <IconButton color="inherit" onClick={handleSettingsClick}>
          <SettingsIcon />
        </IconButton>
        <Menu
          id="customized-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleCloseSettings}
        >
          <MenuItem onClick={handleLogout}>
            <ListItemIcon className={classes.listItemIcon}>
              <ExitToAppIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Odjava" />
          </MenuItem>
          <MenuItem onClick={toggleChangePasswordDialog}>
            <ListItemIcon className={classes.listItemIcon}>
              <EditIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Promeni lozinku" />
          </MenuItem>
        </Menu>
      </Toolbar>
      <ChangePassword
        open={changePassword}
        toggleDialogOpen={toggleChangePasswordDialog}
      />
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  listItemIcon: {
    minWidth: "auto",
    marginRight: theme.spacing(1),
  },
}));

export default React.memo(AppTololbar);
