import {
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";
import React from "react";
import { createStyles, withStyles } from "@material-ui/styles";

const StyledTableSortLabel = withStyles((theme) =>
  createStyles({
    root: {
      color: "white",
      "&:hover": {
        color: "white",
      },
      "&$active": {
        color: "white",
      },
    },
    active: {},
    icon: {
      color: "white !important",
    },
  })
)(TableSortLabel);

const DefaultHead = ({ headerGroups, headerStyle }) => {
  return (
    <TableHead>
      {headerGroups.map((headerGroup) => (
        <TableRow
          className={headerStyle}
          {...headerGroup.getHeaderGroupProps()}
        >
          {headerGroup.headers.map((column) => {
            return (
              <TableCell
                key={column.id}
                {...(column.id === "open"
                  ? column.getHeaderProps()
                  : column.getHeaderProps(column.getSortByToggleProps()))}
              >
                {column.render("Header")}
                {column.id !== "open" ? (
                  <StyledTableSortLabel
                    active={column.isSorted}
                    direction={column.isSortedDesc ? "desc" : "asc"}
                  />
                ) : null}
              </TableCell>
            );
          })}
        </TableRow>
      ))}
    </TableHead>
  );
};

export default DefaultHead;
