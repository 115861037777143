import { useQueryClient, useMutation } from "react-query";
import { makeRequest } from "../request/makeRequest";

const AUTH = "auth";

const login = (body) =>
  makeRequest(`${AUTH}/login`, {
    method: "POST",
    body,
  });

export function useLogin() {
  // const queryClient = useQueryClient();

  return useMutation(login, {
    onSuccess: () => {
      // queryClient.invalidateQueries(QUERY_KEYS.appSettings);
    },
    onError: (error) => {},
  });
}
