import React from "react";
import { Box, LinearProgress, Typography } from "@material-ui/core";

const Loader = ({ height = "85vh" }) => {
  return (
    <Box
      height={height}
      display="flex"
      flexDirection="column"
      justifyContent="center"
    >
      <Typography className="text-20 mb-16" color="textSecondary">
        Loading...
      </Typography>
      <LinearProgress color="primary" />
    </Box>
  );
};

export default Loader;
