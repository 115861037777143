import { Box, Grid, Paper, Typography, makeStyles } from "@material-ui/core";
import InputField from "components/controls/InputField";
import React from "react";
import { useFormContext } from "react-hook-form";
import { brandOptions, operatingSystemOptions } from "./constants";
import ImageSection from "./ImageSection";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Select from "components/controls/Select";

function RequiredFieldsSection({
  mobileDetails,
  createNew,
  onSetImageFiles,
  imageFiles,
}) {
  const methods = useFormContext();
  const history = useHistory();

  const isCopyAction = history.location.pathname.includes("copy");
  const classes = useStyles();
  return (
    <Paper>
      <Box p={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <InputField
              margin="dense"
              control={methods.control}
              defaultValue={mobileDetails?.name}
              fullWidth
              variant="outlined"
              name="name"
              label="Naziv ***"
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <InputField
              type="number"
              margin="dense"
              control={methods.control}
              defaultValue={mobileDetails?.price}
              fullWidth
              variant="outlined"
              name="price"
              label="Cena (EUR) ***"
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Select
              margin="dense"
              options={brandOptions}
              control={methods.control}
              defaultValue={
                mobileDetails?.brand
                  ? {
                      value: mobileDetails?.brand?.toLowerCase(),
                      name: mobileDetails?.brand,
                    }
                  : null
              }
              fullWidth
              variant="outlined"
              name="brand"
              label="Brend ***"
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Select
              margin="dense"
              options={operatingSystemOptions}
              control={methods.control}
              defaultValue={
                mobileDetails?.operatingSystem
                  ? {
                      value: mobileDetails?.operatingSystem.toLowerCase(),
                      name: mobileDetails?.operatingSystem,
                    }
                  : null
              }
              fullWidth
              variant="outlined"
              name="operatingSystem"
              label="Operativni sistem ***"
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <InputField
              margin="dense"
              control={methods.control}
              defaultValue={mobileDetails?.ramMemory}
              fullWidth
              variant="outlined"
              name="ramMemory"
              label="Ram memorija (GB) ***"
              type="number"
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <InputField
              margin="dense"
              control={methods.control}
              defaultValue={mobileDetails?.internalMemory}
              fullWidth
              variant="outlined"
              name="internalMemory"
              type="number"
              label="Interna memorija (GB) ***"
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <InputField
              margin="dense"
              control={methods.camera}
              defaultValue={mobileDetails?.mainCamera}
              fullWidth
              type="number"
              variant="outlined"
              name="mainCamera"
              label="Glavna kamera (MP) ***"
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <InputField
              margin="dense"
              control={methods.control}
              defaultValue={mobileDetails?.description}
              rows={10}
              multiline
              fullWidth
              maxLength={500}
              variant="outlined"
              name="description"
              label="Dodatni detalji o vašem telefonu"
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Typography className={classes.heading}>
              Slike (minimalno 1 - maksimalno 3)
            </Typography>
            <ImageSection
              mobileId={isCopyAction ? null : mobileDetails?._id}
              createNew={createNew}
              setImageFiles={onSetImageFiles}
              imageFiles={imageFiles}
            />
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
}

export default RequiredFieldsSection;

const useStyles = makeStyles((theme) => ({
  heading: {
    display: "flex",
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightMedium,
    textDecoration: "underline",
    textTransform: "uppercase",
  },
}));
