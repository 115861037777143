import Login from "pages/Login/Login";
import React from "react";
import {
  HashRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import AppLayout from "./layouts/AppLayout/AppLayout";

import MobileItemPage from "pages/MobileItemPage/MobileItemPage";
import MobilePage from "pages/MobilePage/MobilePage";

import Register from "pages/Register/Register";
import { ROUTES } from "lib/constants";
import UserProfilePage from "pages/UserProfilePage/UserProfilePage";

const RouterWrap = () => {
  const sessionActive = sessionStorage.getItem("MEC-TOKEN");

  if (!sessionActive) {
    return (
      <Router>
        <Switch>
          <Route path={ROUTES.register}>
            <Register />
          </Route>

          <Route path={ROUTES.login}>
            <Login />
          </Route>
          <Redirect to={ROUTES.login}>
            <Login />
          </Redirect>
        </Switch>
      </Router>
    );
  }
  return (
    <Router>
      <AppLayout>
        <Switch>
          {/* <Route exact path={ROUTES.dashboard}>
            <Dashboard />
          </Route> */}
          <Route exact path={ROUTES.userProfile}>
            <UserProfilePage />
          </Route>
          <Route exact path={ROUTES.mobiles}>
            <MobilePage />
          </Route>
          <Route exact path={`${ROUTES.mobiles}/:id`}>
            <MobileItemPage />
          </Route>
          <Route exact path={`${ROUTES.mobiles}/:id/copy`}>
            <MobileItemPage />
          </Route>
          <Redirect to={ROUTES.mobiles}>
            <MobilePage />
          </Redirect>
        </Switch>
      </AppLayout>
    </Router>
  );
};

export default RouterWrap;
