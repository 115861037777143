import React, { useEffect } from "react";
import ReactHtmlParser from "react-html-parser";

import { Typography } from "@material-ui/core";

const DefaultCell = ({ value: initialValue, column: { id } }) => {
  const [value, setValue] = React.useState(initialValue);
  useEffect(() => {
    setValue(initialValue?.toString());
  }, [initialValue]);

  return <Typography>{ReactHtmlParser(value)}</Typography>;
};

export default DefaultCell;
