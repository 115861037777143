import { useMutation } from "react-query";
import { makeRequest } from "../request/makeRequest";

const AUTH = "auth";

const changePassword = (body) =>
  makeRequest(`${AUTH}/password/change`, {
    method: "PATCH",
    body,
  });

export function useChangePassword() {
  return useMutation(changePassword);
}
