import React from "react";

import { DropzoneArea } from "material-ui-dropzone";
import { makeStyles } from "@material-ui/core";

import { toast } from "react-toastify";

const useStyles = makeStyles({
  root: {
    maxHeight: "150px",
    minHeight: "120px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& svg": {
      display: "none",
    },
  },
});

const UploadImage = ({
  setImageFiles,
  imageFiles,
  mobileImages = [],
  ...props
}) => {
  const classes = useStyles();
  const maxImagesReached = imageFiles.length + mobileImages.length >= 3;

  const _handleImageDrop = async (files) => {
    const filesSliced = files.slice(0, 3);

    setImageFiles((prev) => {
      return [...prev, ...filesSliced];
    });
  };

  const handleDropRejected = (file) => {
    toast.error(
      `Slika ${file[0]?.name} nije podržana! Veličina slike mora biti manja od 50MB!`
    );
  };

  const handleDeleteFromPreview = (file) => {
    setImageFiles((state) =>
      state.filter((item) => item[0]?.name !== file.name)
    );
  };

  return (
    <>
      <DropzoneArea
        showAlerts={false}
        onDropRejected={(file, err) => {
          handleDropRejected(file);
        }}
        fullWidth
        dropzoneProps={{
          disabled: maxImagesReached,
        }}
        maxFileSize={50000000}
        dropzoneClass={classes.root}
        dropzoneText={
          maxImagesReached
            ? "Izaban je maksimalna broj slika (3)!!!"
            : "Prevuci sliku ili klikni za dodavanje"
        }
        acceptedFiles={["image/*"]}
        onDelete={(file) => {
          handleDeleteFromPreview(file);
        }}
        filesLimit={3}
        onDrop={_handleImageDrop}
        showPreviews={true}
        // useChipsForPreview
        showPreviewsInDropzone={false}
        {...props}
      />
    </>
  );
};
export default UploadImage;
