import { useQuery } from "react-query";
import { makeRequest } from "../request/makeRequest";
import { MOBILE_URL, QUERY_KEYS } from "store/constants";

const fetchMobileDetails = (id) =>
  makeRequest(`${MOBILE_URL}/${id}`, {
    method: "GET",
  });

export function useMobileItem({ id }) {
  return useQuery([QUERY_KEYS.mobileItem, id], () => fetchMobileDetails(id), {
    enabled: !!id,
  });
}
