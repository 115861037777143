import React from "react";

import TextField from "@material-ui/core/TextField";

import { useController, useFormContext } from "react-hook-form";

const InputField = ({ control, name, defaultValue = "", ...props }) => {
  const methods = useFormContext();
  const errors = methods?.errors;
  const {
    field: { ref, ...inputProps },
    meta: { invalid, isTouched, isDirty },
  } = useController({
    name,
    control,
    rules: { maxLength: props?.maxLength || 300 },
    defaultValue,
  });
  return (
    <TextField
      {...props}
      {...inputProps}
      variant="outlined"
      error={invalid}
      helperText={errors ? errors[name]?.message : ""}
    />
  );
};

export default InputField;
