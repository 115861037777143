import { useQueryClient, useMutation } from "react-query";
import { makeRequest } from "../request/makeRequest";
import { IMAGE_URL, QUERY_KEYS } from "store/constants";

const setMainImage = ({ imageId, mobileId }) => {
  if (!imageId || !mobileId) return;
  try {
    makeRequest(`${IMAGE_URL}/${imageId}/main`, {
      method: "PATCH",
      body: {
        mobileId,
      },
    });
  } catch (error) {
    console.log(error, error.message, "erorr in createSingleImage");
  }
};

export function useSetMainImage() {
  const queryClient = useQueryClient();

  return useMutation(setMainImage, {
    onSuccess: () => {
      setTimeout(() => {
        queryClient.invalidateQueries(QUERY_KEYS.mobileItemImages);
      }, 200);
    },
    onError: (error) => {},
  });
}
