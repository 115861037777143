import { useQuery } from "react-query";
import { makeRequest } from "../request/makeRequest";
import { MOBILE_URL, QUERY_KEYS } from "store/constants";

const fetchMobiles = (payload) =>
  makeRequest(
    `${MOBILE_URL}?showExpired=true&limit=${payload?.limit || 20}&offset=${
      payload?.offset || 0
    }`,
    {
      method: "GET",
    }
  );

export function useMobileList(payload) {
  return useQuery(
    [QUERY_KEYS.mobileList, payload.offset, payload.limit],
    () => fetchMobiles(payload),
    {
      enabled: true,
    }
  );
}
