import React from "react";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { themes } from "./themeConfig";

const MainTheme = ({ children }) => {
  const defaultTheme = themes.default;
  const theme = createMuiTheme(themes[defaultTheme]);

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default React.memo(MainTheme);
