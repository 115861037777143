import React from "react";
import { useForm } from "react-hook-form";

import Avatar from "@material-ui/core/Avatar";
import SettingsBackupRestoreIcon from "@material-ui/icons/SettingsBackupRestore";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import InputField from "components/controls/InputField";
import PrimaryButton from "components/controls/PrimaryButton";
import { Button, Dialog, DialogActions, DialogTitle } from "@material-ui/core";
import { toast } from "react-toastify";

import { useResetPassword } from "store/api/hooks/useResetPassword";

const ResetPassword = ({ toggleDialogOpen, open }) => {
  const { register, handleSubmit, errors, control } = useForm();

  const { mutate: onResetPassword, isLoading } = useResetPassword();
  const classes = useStyles();

  const handleReset = (values) => {
    onResetPassword(
      { email: values.email },
      {
        onSuccess: async (res) => {
          toggleDialogOpen();
          toast.success("Lozinka je resetovana i poslata na vaš e-mail");
        },
        onError: (err) => {
          toast.error(err.response.data.error);
        },
      }
    );
  };
  return (
    <Dialog
      open={open}
      onClose={toggleDialogOpen}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
        Reset lozinke
      </DialogTitle>
      <form className={classes.form} onSubmit={handleSubmit(handleReset)}>
        <Container component="main" maxWidth="xs">
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <SettingsBackupRestoreIcon />
            </Avatar>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <InputField
                  fullWidth
                  control={control}
                  register={register}
                  name="email"
                  placeholder=""
                  label="E-mail adresa"
                  error={errors?.email?.message}
                />
              </Grid>
            </Grid>
          </div>
        </Container>
        <DialogActions>
          <Button autoFocus onClick={toggleDialogOpen} color="primary">
            Izađi
          </Button>
          <PrimaryButton isLoading={isLoading} color="primary" type="submit">
            Resetuj
          </PrimaryButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ResetPassword;

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    minWidth: 320,
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
}));
