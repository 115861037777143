import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import DashboardIcon from "@material-ui/icons/Dashboard";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import SmartphoneIcon from "@material-ui/icons/Smartphone";
import { Link } from "react-router-dom";
import { ROUTES } from "lib/constants";

export const navigationItems = (
  <div>
    {/* <Link to="/dashboard">
      <ListItem button>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </ListItem>
    </Link> */}

    <Link to={ROUTES.mobiles}>
      <ListItem button>
        <ListItemIcon>
          <SmartphoneIcon />
        </ListItemIcon>
        <ListItemText primary="Telefoni" />
      </ListItem>
    </Link>
    <Link to={ROUTES.userProfile}>
      <ListItem button>
        <ListItemIcon>
          <AccountBoxIcon />
        </ListItemIcon>
        <ListItemText primary="Profil" />
      </ListItem>
    </Link>
  </div>
);
