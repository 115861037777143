import { useMutation } from "react-query";
import { makeRequest } from "../request/makeRequest";

const AUTH = "auth";

const resetPassword = (body) =>
  makeRequest(`${AUTH}/password/reset`, {
    method: "PATCH",
    body,
  });

export function useResetPassword() {
  return useMutation(resetPassword);
}
