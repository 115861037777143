import { Button, Dialog, DialogActions } from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { ROUTES } from "lib/constants";
import { handleQueryErrors } from "lib/handleQueryErrors";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import { useDeleteMobile } from "store/api/hooks/useDeleteMobile";

export default function DeleteDialog({
  mobileDetails,
  open,
  toggleDeleteDialog,
}) {
  const { mutateAsync: onDelete } = useDeleteMobile();
  const history = useHistory();

  const handleDeleteMobileItem = () => {
    onDelete(
      { id: mobileDetails._id },
      {
        onSuccess: () => {
          toast.success("Telefon je uspešno obrisan");
          toggleDeleteDialog();
          history.push(ROUTES.mobiles);
        },
        onError: handleQueryErrors,
      }
    );
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={toggleDeleteDialog}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          Obriši {mobileDetails?.name}?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Da li ste sigurni da želite obrisati izabrani telefon?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={toggleDeleteDialog} color="primary">
            Izađi
          </Button>
          <Button onClick={handleDeleteMobileItem} color="primary">
            Obriši
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
