import { Button, Dialog, DialogActions } from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { ROUTES } from "lib/constants";
import { handleQueryErrors } from "lib/handleQueryErrors";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import { useDeleteMobile } from "store/api/hooks/useDeleteMobile";
import { useUpdateMobile } from "store/api/hooks/useUpdateMobile";

export default function ReviewDialog({
  mobileDetails,
  open,
  toggleReviewDialog,
}) {
  const { mutateAsync: onUpdateMobile, isLoading: updateInProgress } =
    useUpdateMobile();

  const handleDeleteMobileItem = () => {
    onUpdateMobile(
      {
        id: mobileDetails?._id,
        renewedAt: new Date(),
        expired: false,
      },
      {
        onSuccess: async () => {
          toast.success("Telefon uspesno obnovnljen");
          toggleReviewDialog();
        },
        onError: (error) => {
          const errMsg = error?.response?.data?.error;
          toast.error(errMsg);
        },
      }
    );
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={toggleReviewDialog}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          Obnovi oglas za {mobileDetails?.name}?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Da li ste sigurni da želite da obnovite oglas?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={toggleReviewDialog} color="primary">
            Izađi
          </Button>
          <Button onClick={handleDeleteMobileItem} color="primary">
            Obnovi
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
