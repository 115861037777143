import React from "react";
import { useForm } from "react-hook-form";

import Avatar from "@material-ui/core/Avatar";
import CssBaseline from "@material-ui/core/CssBaseline";

import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import InputField from "components/controls/InputField";
import PrimaryButton from "components/controls/PrimaryButton";
import { Link } from "@material-ui/core";
import { useLogin } from "store/api/hooks/useLogin";
import { toast } from "react-toastify";
import GoogleAuthBtn from "components/GoogleAuthBtn/GoogleAuthBtn";
import { useState } from "react";
import { useCallback } from "react";
import ResetPassword from "components/ResetPassword/ResetPassword";

const Login = () => {
  const [resetPassword, setResetPassword] = useState(false);
  const { register, handleSubmit, errors, control } = useForm();

  const { mutate: onLogin, isLoading } = useLogin();
  const classes = useStyles();

  const toggleResetPasswordDialog = useCallback(() => {
    setResetPassword((prev) => !prev);
  }, []);

  const onSubmit = (values) => {
    onLogin(
      { ...values, username: values.email },
      {
        onSuccess: async (res) => {
          const token = res.data?.token;
          sessionStorage.setItem("MEC-TOKEN", token);

          setTimeout(() => {
            // window.location.href = "#/mobiles";
            window.location.reload();
          }, 1000);
        },
        onError: (err) => {
          console.log(err.response, "err herer");
          toast.error(err.response.data.error);
        },
      }
    );
  };
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Prijava
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <InputField
                fullWidth
                control={control}
                register={register}
                name="email"
                placeholder=""
                label="E-mail adresa"
                error={errors?.email?.message}
              />
            </Grid>
            <Grid item xs={12}>
              <InputField
                fullWidth
                control={control}
                register={register}
                name="password"
                placeholder=""
                label="Lozinka"
                type="password"
                error={errors?.password?.message}
              />
            </Grid>
            <Grid item xs={12}>
              <div>
                Nemas nalog?{" "}
                <Link variant="body" href="#/auth/register">
                  Registruj se ovde
                </Link>
              </div>
            </Grid>
            <Grid item xs={12}>
              <GoogleAuthBtn />
            </Grid>
            <Grid item xs={12}>
              <PrimaryButton
                type="submit"
                isLoading={isLoading}
                color="primary"
                fullWidth
              >
                Prijavi se
              </PrimaryButton>
            </Grid>
            <Grid item xs={12}>
              <div>
                Zaboravili ste lozinku?{" "}
                <Link variant="body" onClick={toggleResetPasswordDialog}>
                  Resetujte je ovde
                </Link>
              </div>
            </Grid>
          </Grid>
        </form>
      </div>
      {resetPassword && (
        <ResetPassword
          open={resetPassword}
          toggleDialogOpen={toggleResetPasswordDialog}
        />
      )}
    </Container>
  );
};

export default Login;

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    width: "80%",
    display: "flex",
    margin: "0 auto",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
}));
