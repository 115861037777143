import React from "react";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

const PrimaryButton = ({ icon, ...props }) => {
  const isLoading = props.isLoading;

  return (
    <Button
      variant="contained"
      disabled={isLoading || props.disabled}
      {...props}
    >
      {isLoading ? (
        <CircularProgress size={24} style={{ color: "white" }} />
      ) : (
        <>
          {icon && (
            <span style={{ marginRight: "8px", display: "flex" }}>{icon}</span>
          )}{" "}
          {props.children}
        </>
      )}
    </Button>
  );
};

export default PrimaryButton;
