import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";

import { makeStyles } from "@material-ui/core/styles";
import { Avatar, Link } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import { Typography, Container } from "@material-ui/core";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";

import InputField from "components/controls/InputField";
import PrimaryButton from "components/controls/PrimaryButton";
import { toast } from "react-toastify";
import { useRegister } from "store/api/hooks/useRegister";
import { ROUTES } from "lib/constants";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const defaultValues = {
  termsOfUse: false,
};

const Register = () => {
  const history = useHistory();

  const classes = useStyles();

  const [disabled, setDisabledStatus] = useState(true);

  const { mutate: onRegister, isLoading } = useRegister();

  const _handleUserRegister = async (data) => {
    const { firstName, lastName, password, email, phoneNumber } = data;
    const payload = {
      firstName,
      lastName,
      email,
      password,
      phoneNumber,
    };

    onRegister(payload, {
      onSuccess: (res) => {
        toast.success(res.data.data);
        localStorage.setItem("email", email);
        history.push(ROUTES.login);
      },
      onError: (error) => {
        const errMsg = error?.response?.data?.error;
        toast.error(errMsg);
      },
    });
  };

  const _handleSubmitBtnDisplay = () => {
    const formValues = methods.watch([
      "firstName",
      "lastName",
      "password",
      "email",
      "termsOfUse",
    ]);
    const errorExist = Object.entries(methods.errors).length > 0;
    for (let item in formValues) {
      if (!formValues[item] || errorExist) {
        if (!disabled) {
          setDisabledStatus(true);
        }
        return;
      }
    }
    setDisabledStatus(false);
  };

  const methods = useForm({ defaultValues });

  return (
    <>
      <Container component="main" maxWidth="xs">
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Registruj se
          </Typography>
          <form
            onChange={_handleSubmitBtnDisplay}
            className={classes.form}
            onSubmit={methods.handleSubmit(_handleUserRegister)}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <InputField
                  control={methods.control}
                  name="firstName"
                  variant="outlined"
                  fullWidth
                  label="Ime"
                  autoFocus
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputField
                  control={methods.control}
                  variant="outlined"
                  fullWidth
                  label="Prezime"
                  name="lastName"
                  autoComplete="lname"
                />
              </Grid>
              <Grid item xs={12}>
                <InputField
                  control={methods.control}
                  variant="outlined"
                  fullWidth
                  label="Email adresa"
                  name="email"
                  autoComplete="email"
                />
              </Grid>
              <Grid item xs={12}>
                <InputField
                  control={methods.control}
                  variant="outlined"
                  fullWidth
                  label="Kontakt telefon"
                  name="phoneNumber"
                  autoComplete="pNumber"
                />
              </Grid>
              <Grid item xs={12}>
                <InputField
                  variant="outlined"
                  fullWidth
                  control={methods.control}
                  id="password"
                  type="password"
                  name="password"
                  label="Lozinka"
                />
              </Grid>
              <Grid item xs={12}>
                <div>
                  Imas nalog?{" "}
                  <Link variant="body" href="#/auth/login">
                    Prijavi se ovde
                  </Link>
                </div>
              </Grid>
            </Grid>
            <PrimaryButton
              isLoading={isLoading}
              type="submit"
              fullWidth
              style={{
                marginTop: "20px",
              }}
              variant="contained"
              color="primary"
            >
              Registruj se
            </PrimaryButton>
          </form>
        </div>
      </Container>
    </>
  );
};
export default Register;
