import React, { useCallback, useMemo, useState } from "react";
import { useHistory, withRouter } from "react-router-dom";

import DefaultCell from "components/common/ReactTable/DefaultCell";
import DefaultHead from "components/common/ReactTable/DefaultHead";

import ReactTable from "components/common/ReactTable/ReactTable";

import { Box, Icon, Paper } from "@material-ui/core";
import PrimaryButton from "components/controls/PrimaryButton";
import DeleteDialog from "./MobileItemForm/DeleteDialog";
import { ROUTES } from "lib/constants";
import ReviewDialog from "./MobileItemForm/ReviewDialog";
import useIsMobile from "lib/hooks/useIsMobile";

const getColumns = (isMobile) => {
  const data = [
    // {
    //   Header: "ID",
    //   accessor: "_id",
    // },
    {
      Header: "Telefon",
      accessor: "name",
      // disableSortBy: false,
    },
  ];

  if (!isMobile) {
    data.push(
      {
        Header: "Cena",
        accessor: "price",
      },
      {
        Header: "Brend",
        accessor: "brand",
      },
      {
        Header: "O. Sistem",
        accessor: "operatingSystem",

        //filter: "includesAll", // include all of selected items
        //filter: "includesSome", // include some of selected
      },
      // {
      //   Header: "Screen Size",
      //   accessor: "screenSize",
      // },
      {
        Header: "Glavna Kamera",
        accessor: "mainCamera",
      },
      {
        Header: "Selfi Kamera",
        accessor: "selfiCamera",
      },
      {
        Header: "Ram M.",
        accessor: "ramMemory",
      },
      {
        Header: "Interna M.",
        accessor: "internalMemory",
      },
      {
        Header: "Status",
        accessor: "expired",
        Cell: ({ row: { original } }) => (
          <span>{original?.expired ? "Istekao" : "Aktivan"}</span>
        ),
      }
    );
  }
  return data;
};

const MobileList = ({ mobileList = [] }) => {
  const history = useHistory();
  const isMobile = useIsMobile();
  const [showDeleteMobileDialog, setShowDeleteMobileDialog] = useState(false);
  const [showReviewMobileDialog, setShowReviewMobileDialog] = useState(false);

  const [selectedMobile, setSelectedMobile] = useState(null);

  const toggleDeleteDialog = useCallback(() => {
    setShowDeleteMobileDialog((prev) => !prev);
  }, []);

  const toggleReviewDialog = useCallback(() => {
    setShowReviewMobileDialog((prev) => !prev);
  }, []);

  const deleteAction = (data) => {
    setSelectedMobile(data);
    toggleDeleteDialog();
  };

  const renewAction = (data) => {
    setSelectedMobile(data);
    toggleReviewDialog();
  };

  const addNewMobile = () => {
    history.push(`${ROUTES.mobiles}/new`);
  };

  const copyAction = (data) => {
    history.push(`${ROUTES.mobiles}/${data._id}/copy`);
  };
  const openAction = (data) => {
    history.push(`${ROUTES.mobiles}/${data._id}`);
  };

  const handleActionsClick = () => {
    return {
      copyAction,
      openAction,
      deleteAction,
      renewAction,
    };
  };

  const rows = useMemo(
    () =>
      mobileList.map((item, index) => {
        const {
          _id,
          name,
          brand,
          price,
          operatingSystem,
          screenSize,
          mainCamera,
          ramMemory,
          selfiCamera,
          internalMemory,
          expired,
        } = item;

        return {
          _id,
          name,
          brand,
          price,
          operatingSystem,
          selfiCamera,
          screenSize,
          mainCamera,
          ramMemory,
          internalMemory,
          expired,
        };
      }),
    [mobileList]
  );

  const columns = getColumns(isMobile);

  return (
    <Paper
      style={{
        padding: isMobile ? 10 : 20,
      }}
    >
      <Box display="flex" justifyContent="flex-end" mb={2}>
        <PrimaryButton
          endIcon={<Icon>add</Icon>}
          color="primary"
          onClick={addNewMobile}
        >
          Dodaj telefon
        </PrimaryButton>
      </Box>

      <ReactTable
        disabled={false}
        columns={columns}
        data={rows}
        showAction={true}
        CustomTableHead={DefaultHead}
        DefaultCell={DefaultCell}
        handleActionsClick={handleActionsClick}
        // TableFilter={BusinessTableFilter}
      />
      {showDeleteMobileDialog && (
        <DeleteDialog
          mobileDetails={selectedMobile}
          open={showDeleteMobileDialog}
          toggleDeleteDialog={toggleDeleteDialog}
        />
      )}
      {showReviewMobileDialog && (
        <ReviewDialog
          mobileDetails={selectedMobile}
          open={showReviewMobileDialog}
          toggleReviewDialog={toggleReviewDialog}
        />
      )}
    </Paper>
  );
};

export default withRouter(MobileList);
