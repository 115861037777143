import { Box, Grid } from "@material-ui/core";
import PrimaryButton from "components/controls/PrimaryButton";
import React, { useCallback, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import DeleteDialog from "./DeleteDialog";
import { ROUTES } from "lib/constants";

const ActionBtnsSection = ({ createNew, isLoading, mobileDetails }) => {
  const history = useHistory();

  const [showDeleteMobileDialog, setShowDeleteMobileDialog] = useState(false);

  const toggleDeleteDialog = useCallback(() => {
    setShowDeleteMobileDialog((prev) => !prev);
  }, []);

  const onCopyMobile = (mobileId) => {
    history.push(`${ROUTES.mobiles}/${mobileId}/copy`);
  };

  const expired = mobileDetails?.expired;

  return (
    <Box mt={2}>
      {expired ? (
        <Grid container spacing={1}>
          <Grid item xs={12} sm={2}>
            <PrimaryButton fullWidth type="submit" color="primary">
              Obnovi oglas
            </PrimaryButton>
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={1}>
          <Grid item xs={12} sm={2}>
            <PrimaryButton
              fullWidth
              color="primary"
              type="submit"
              disabled={isLoading}
            >
              {createNew ? "Dodaj" : "Ažuriraj"}
            </PrimaryButton>
          </Grid>

          <Grid item xs={12} sm={2}>
            {!createNew && (
              <PrimaryButton
                fullWidth
                type="submit"
                color="default"
                onClick={() => {
                  onCopyMobile(mobileDetails?._id);
                }}
              >
                Kopiraj podatke
              </PrimaryButton>
            )}
          </Grid>
          <Grid item xs={12} sm={2}>
            {!createNew && (
              <PrimaryButton
                fullWidth
                color="secondary"
                onClick={toggleDeleteDialog}
              >
                Obriši
              </PrimaryButton>
            )}
          </Grid>
        </Grid>
      )}
      {showDeleteMobileDialog && (
        <DeleteDialog
          mobileDetails={mobileDetails}
          open={showDeleteMobileDialog}
          toggleDeleteDialog={toggleDeleteDialog}
        />
      )}
    </Box>
  );
};

export default ActionBtnsSection;
