import axios from "axios";

import Cookies from "js-cookie";
import { Router } from "react-router";

const axiosApiInstance = axios.create();

// Add a request interceptor
(async (store) => {
  axiosApiInstance.interceptors.request.use(
    (config) => {
      const token = sessionStorage.getItem("MEC-TOKEN");

      if (token) {
        config.headers["Authorization"] = token;
        config.headers["x-api-key"] = "1vaHd3v";
      }
      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );

  // Add a response interceptor
  axiosApiInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    async function (error) {
      return Promise.reject(error);
    }
  );
})();

export default axiosApiInstance;
