import React, { useCallback, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { delay } from "lib/delay";

import * as yup from "yup";

import { Box, Button, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { yupResolver } from "@hookform/resolvers/yup";
import { mobileAPI } from "store/api/mobile.api";

import ImageSection from "./ImageSection";
import ModelSection from "./ModelSection";
import BodySection from "./BodySection";
import DisplaySection from "./DisplaySection";
import PlatformSection from "./PlatformSection";
import MemorySection from "./MemorySection";
import CameraSection from "./CameraSection";
import CommsAndSound from "./CommsAndSound";
import DescriptionSection from "./DescriptionSection";
import { useHistory } from "react-router-dom";

import { useCreateMobile } from "store/api/hooks/useCreateMobile";
import { useUserData } from "store/api/hooks/useUserData";
import { useUpdateMobile } from "store/api/hooks/useUpdateMobile";
import { toast } from "react-toastify";
import ActionBtnsSection from "./ActionBtnsSection";

import { useCreateMultipleImages } from "store/api/hooks/useCreateMultipleImages";
import { useMobileImages } from "store/api/hooks/useGetMobileImages";
import WebPages from "../WebPages";
import { ROUTES } from "lib/constants";
import { useCreateSingleImage } from "store/api/hooks/useCreateSingleImage";
import { QUERY_KEYS } from "store/constants";
import { useQueryClient } from "react-query";
import RequiredFieldsSection from "./RequiredFieldsSection";

const requiredValidationText = "Ovo polje je obavezno!";
const numberValidationText = "Ovo polje mora biti broj!";
const zeroMinValueValidationText = "Minimalna vrednost je 0!";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    display: "flex",
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightMedium,
    textDecoration: "underline",
    textTransform: "uppercase",
  },
}));

const translateFieldKey = (key) => {
  switch (key) {
    case "name":
      return "Ime";
    case "internalMemory":
      return "Interna memorija";
    case "mainCamera":
      return "Glavna kamera";
    case "brand":
      return "Brend";
    case "operatingSystem":
      return "Operativni sistem";
    case "price":
      return "Cena";
    case "ramMemory":
      return "RAM memorija";
    default:
      return key;
  }
};

const MobileItemForm = ({ mobileDetails, createNew }) => {
  const queryClient = useQueryClient();
  const history = useHistory();

  const isCopyAction = history.location.pathname.includes("copy");
  const isNewAction = history.location.pathname.includes("new");

  const [imageFiles, setImageFiles] = useState([]);
  const { data: response } = useUserData();
  const user = response?.data;

  const validationSchema = yup.object().shape({
    name: yup
      .string()
      .min(2, "Ime telefona mora imati bar 2 karaktera")
      .max(50, "Maksimalan broj karaktera je 50")
      .required(requiredValidationText),

    description: yup
      .string()
      .min(2, "Minimalan broj karatkera je 2")
      .max(500, "Maksimalan broj karaktera je 500")
      .required(requiredValidationText),
    operatingSystem: yup.mixed().required(requiredValidationText),
    brand: yup.mixed().nullable(false).required(requiredValidationText),

    price: yup.number(numberValidationText).required(requiredValidationText),
    ramMemory: yup
      .number()
      .typeError(numberValidationText)
      .min(0, zeroMinValueValidationText)
      .max(1000, "Maksimalna vrednost je 1000")
      .required(requiredValidationText),
    internalMemory: yup
      .number()
      .typeError(numberValidationText)

      .min(0, zeroMinValueValidationText)
      .max(1000, "Maksimalna vrednost je 1000")
      .required(requiredValidationText),
    mainCamera: yup
      .number()
      .typeError(numberValidationText)

      .min(0, zeroMinValueValidationText)
      .max(1000, "Maksimalna vrednost je 1000")
      .required(requiredValidationText),
  });

  const methods = useForm({
    resolver: yupResolver(validationSchema),
  });

  const { data: imagesResponse } = useMobileImages({
    id: isCopyAction ? null : mobileDetails?._id,
  });
  const imageList = imagesResponse?.data?.data;

  const { mutateAsync: onCreateMobile, isLoading: createInProgress } =
    useCreateMobile();

  const { mutateAsync: onUpdateMobile, isLoading: updateInProgress } =
    useUpdateMobile();

  const { mutateAsync: onCreateMultipleImages } = useCreateMultipleImages();
  const { mutateAsync: onCreateSingleImage } = useCreateSingleImage();

  const classes = useStyles();

  const onSetImageFiles = useCallback((files) => {
    setImageFiles(files);
  }, []);

  const createMultipleImages = async (imageFiles, id) => {
    try {
      imageFiles.forEach(async (files) => {
        await delay(1000);
        await mobileAPI.setMobileImage({ id, files });
      });
    } catch (error) {
      toast.error(error?.message);
    }
  };

  const handleSaveMobile = useCallback(
    async (payload) => {
      if (!imageFiles?.length && !imageList?.length) {
        toast.error("Jedna slika je obavezna");
        return;
      }

      const newPayload = {
        ...payload,
        owner: user?._id,
        operatingSystem: payload.operatingSystem.value,
        brand: payload.brand.value,
        ratingList: [],
      };

      if (mobileDetails?.expired) {
        onUpdateMobile(
          {
            id: mobileDetails?._id,
            createdAt: new Date(),
            expired: false,
          },
          {
            onSuccess: async () => {
              toast.success("Telefon uspesno obnovnljen");
            },
            onError: (error) => {
              const errMsg = error?.response?.data?.error;
              toast.error(errMsg);
            },
          }
        );
        return;
      }

      if (createNew || isCopyAction) {
        onCreateMobile(newPayload, {
          onSuccess: async (res) => {
            const newMobileId = res?.data?.id;
            toast.success("Telefon uspesno dodat");
            await imageFiles.forEach((image, index) => {
              onCreateSingleImage({
                file: image,
                id: newMobileId,
                isMain: index === 0,
              });
            });
            // if (isCopyAction) {
            //   queryClient.refetchQueries(QUERY_KEYS.mobileItemImages);
            // } else {
            //   history.push(`${ROUTES.mobiles}/${newMobileId}`);
            // }
            history.push(`${ROUTES.mobiles}`);

            // createMultipleImages(imageFiles, newMobileId);
            // onCreateMultipleImages(
            //   { imageFiles, id: newMobileId },
            //   {
            //     onSuccess: () => {
            //       history.push(ROUTES.mobiles);
            //     },
            //   }
            // );
          },
          onError: (error) => {
            const errMsg = error?.response?.data?.error;
            toast.error(errMsg);
          },
        });
      } else {
        onUpdateMobile(newPayload, {
          onSuccess: async () => {
            // createMultipleImages(imageFiles, payload.id);
            toast.success("Telefon uspesno azuriran");
            await imageFiles.forEach((image) => {
              onCreateSingleImage({
                file: image,
                id: mobileDetails?._id,
                isMain: image?.isMain,
              });
            });
            await delay(1000);
            queryClient.refetchQueries(QUERY_KEYS.mobileItemImages);
            // history.push(ROUTES.mobiles);

            // onCreateMultipleImages({ imageFiles, id: payload.id });
          },
          onError: (error) => {
            const errMsg = error?.response?.data?.error;
            toast.error(errMsg);
          },
        });
      }
    },
    [
      user?._id,
      createNew,
      isCopyAction,
      onCreateMobile,
      imageFiles,
      history,
      onUpdateMobile,
    ]
  );

  useEffect(() => {
    // let errorMsg = "";
    if (methods?.errors) {
      Object.keys(methods?.errors).forEach((key) => {
        const errorMsg = `Polje ${translateFieldKey(key)}: ${
          methods?.errors[key]?.message
        }\n`;
        toast.error(errorMsg);
      });
      // if (errorMsg) {
      //   toast.error(errorMsg);
      // }
    }
  }, [methods?.errors]);

  return (
    <FormProvider {...methods}>
      <Box mb={3} display="flex">
        <Button
          variant="outlined"
          color="primary"
          onClick={() => history.push(ROUTES.mobiles)}
        >
          Nazad
        </Button>
      </Box>
      <Box fontSize={30}>
        {isCopyAction
          ? "Kopiraj podatke"
          : isNewAction
          ? "Dodaj telefon"
          : "Ažuriraj podatke"}
      </Box>

      <WebPages />
      <form onSubmit={methods.handleSubmit(handleSaveMobile)}>
        <Box mb={3}>
          <RequiredFieldsSection
            mobileDetails={mobileDetails}
            createNew={createNew}
            onSetImageFiles={onSetImageFiles}
            imageFiles={imageFiles}
          />
          {/* <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content-model"
              id="panel1a-header-model"
            >
              <Typography className={classes.heading}>Model</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ModelSection
                methods={methods}
                mobileDetails={mobileDetails}
                createNew={createNew || isCopyAction}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content-body"
              id="panel1a-header-body"
            >
              <Typography className={classes.heading}>Kućište</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <BodySection mobileDetails={mobileDetails} methods={methods} />
            </AccordionDetails>
          </Accordion>
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content-display"
              id="panel1a-header-display"
            >
              <Typography className={classes.heading}>Ekran</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <DisplaySection mobileDetails={mobileDetails} methods={methods} />
            </AccordionDetails>
          </Accordion>
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content-platform"
              id="panel1a-header-platform"
            >
              <Typography className={classes.heading}>Platforma</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <PlatformSection
                mobileDetails={mobileDetails}
                methods={methods}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content-memory"
              id="panel1a-header-memory"
            >
              <Typography className={classes.heading}>Memorija</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <MemorySection mobileDetails={mobileDetails} methods={methods} />
            </AccordionDetails>
          </Accordion>
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content-camera"
              id="panel1a-header-camera"
            >
              <Typography className={classes.heading}>Kamera</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <CameraSection mobileDetails={mobileDetails} methods={methods} />
            </AccordionDetails>
          </Accordion>
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content-comms"
              id="panel1a-header-comms"
            >
              <Typography className={classes.heading}>Mreže i zvuk</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <CommsAndSound mobileDetails={mobileDetails} methods={methods} />
            </AccordionDetails>
          </Accordion>
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content-description"
              id="panel1a-header-comms"
            >
              <Typography className={classes.heading}>
                Opis (maksimalno 500 karaktera)
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <DescriptionSection
                mobileDetails={mobileDetails}
                methods={methods}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content-image"
              id="panel1a-header-image"
            >
              <Typography className={classes.heading}>
                Slike (maksimalno 3)
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ImageSection
                mobileId={isCopyAction ? null : mobileDetails?._id}
                createNew={createNew}
                setImageFiles={onSetImageFiles}
                imageFiles={imageFiles}
              />
            </AccordionDetails>
          </Accordion> */}
          <ActionBtnsSection
            mobileDetails={mobileDetails}
            createNew={createNew || isCopyAction}
            isLoading={createInProgress || updateInProgress}
          />
        </Box>
      </form>
    </FormProvider>
  );
};

export default MobileItemForm;
