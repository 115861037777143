import { useMutation } from "react-query";
import { makeRequest } from "../request/makeRequest";

const AUTH = "auth";

const socialLogin = (body) =>
  makeRequest(`${AUTH}/social-login`, {
    method: "POST",
    body,
  });

export function useSocialLogin() {
  return useMutation(socialLogin, {
    onError: (error) => {},
  });
}
